import {
	SurveyDocument,
	SurveyAnswer,
	SurveySection,
	QuestionListItem,
	Question,
	QuestionGroup,
	Option,
	SurveyDocumentStateModel,
	SurveyResponse,
} from "../../../@types/surveys.d";
const { Engine } = require('json-rules-engine');

export const getSelectedAnswer = (
	formItemId: number,
	surveyAnswers: SurveyAnswer[]
) => {
	let givenAnswers = surveyAnswers.filter((a) => a.formItemId === formItemId);
	if (givenAnswers && givenAnswers.length > 0) {
		return givenAnswers[givenAnswers.length - 1].answer;
	}
	return "";
};

export const getSelectedAnswerArray = (
	formItemId: number,
	surveyAnswers: SurveyAnswer[]
) => {
	let givenAnswers = surveyAnswers.filter((a) => a.formItemId === formItemId);
	if (givenAnswers?.length) {
		if (givenAnswers[givenAnswers.length - 1].answer) {
			var values = givenAnswers[givenAnswers.length - 1].answer.split("|");
			return values;
		}
	}
	return [];
};

const getSurveyReviewCards = (state: SurveyDocumentStateModel) => {
	const surveyDocument: SurveyDocument = state.surveyDocument;
	const reviewableSections: SurveySection[] = state.surveySections.filter(
		(surveySection: SurveySection) => surveySection.reviewable
	);

	let mappedResults = reviewableSections.map((surveySection: SurveySection) => {
		let result = {
			sectionId: surveySection.sectionId,
			heading: surveySection.heading,
			optional: surveySection.optional,
			unansweredQuestions: getUnansweredQuestions(
				surveySection,
				state.questionList,
				surveyDocument.survey.instrumentId,
				state.surveyAnswers
			),
			notObservedQuestions: getNotObservedQuestions(
				surveyDocument.survey.instrumentId,
				surveySection,
				state.questionList
			),
			validationRule: surveySection.validationRule,
			numberAnswered: getAnswerCount(
				surveySection,
				state.questionList,
				surveyDocument.survey.instrumentId,
				state.surveyAnswers
			),
			notObservedAnswered: state.questionList.filter(
				(questionListItem: QuestionListItem) =>
					questionListItem.sectionId === surveySection.sectionId &&
					questionListItem.answer &&
					questionListItem.answer === "6"
			).length,
			component: surveySection.component,
		};
		return result;
	});
	return mappedResults;
};

const getUnansweredQuestions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	instrumentId: number,
	surveyAnswers: SurveyAnswer[]
) => {
	// return empty array for competencies because missed questions make no sense
	if (surveySection.component === "SurveyCompetenciesQuestionsComponent") {
		return getUnansweredCompetenciesQuestions(
			surveySection,
			questionList,
			surveyAnswers
		);
	}

	if (surveySection.component === "SurveyDemographicsQuestionsComponent") {
		// for Organizational Challenge survey, validation is a bit different
		if (instrumentId === 1920 || instrumentId === 1860) {
			let question = questionList
				.find(
					(questionListItem: QuestionListItem) =>
						questionListItem.sectionId === surveySection.sectionId &&
						questionListItem.question?.options &&
						questionListItem.question?.options?.find(
							(a) => a.dependentOption !== null && a.dependentOption.answer !== null &&
								a.dependentOption.answer?.trim() !== ""
						)
				)?.question;
			let answer = "";
			let optionAnswer = "";
			let answered = false;
			let options = question?.options?.filter(a => a.dependentOption !== null);
			if (options) {
				options.map(option => {
					if (option.dependentOption.optional === false && option.answer === "1") {
						// get answers from the activeAnswers array so that it is always the current value
						let latestAnswers = surveyAnswers.filter(x => x.formItemId === option.formItemId);
						optionAnswer = latestAnswers.length > 0 ? latestAnswers[latestAnswers.length - 1].answer : option.answer;

						if (optionAnswer === "1") {
							answer = getSelectedAnswer(
								option.dependentOption?.formItemId ?? 0,
								surveyAnswers
							);
							if (answer !== null && answer !== "") {
								answered = true;
							}
							else {
								answered = false;
							}
						}
						else {
							answered = true;
						}
					}
					else {
						// this is optional
						answered = true;
					}
				})

			}
			else {
				answered = true;
				return getUnansweredDemographicQuestions(
					surveySection,
					questionList,
					surveyAnswers
				);
			}

			if (!answered) {
				return getUnansweredDemographicQuestions(
					surveySection,
					questionList,
					surveyAnswers
				);
			}
			else {
				return questionList
					.filter(
						(questionListItem: QuestionListItem) =>
							questionListItem.sectionId === surveySection.sectionId &&
							questionListItem.question?.options &&
							!questionListItem.question?.options?.some(
								(a) => a.answer !== null && a.answer === "1"
							)
					)
					.map((questionListItem: QuestionListItem) => questionListItem.question);
			}
		}

		// this takes into account the optional questions as well to land on that section when there are unanswered questions
		return questionList
			.filter(
				(questionListItem: QuestionListItem) =>
					questionListItem.sectionId === surveySection.sectionId &&
					(!questionListItem.answer ||
						questionListItem.answer === null ||
						questionListItem.answer === "")
			)
			.map((questionListItem: QuestionListItem) => questionListItem.question);
	}

	// AAndB question demand special attention because their answer form ID are in
	// the questions options collection.
	if (surveySection.component === "SurveyAAndBQuestionsComponent") {
		return getUnansweredAAndBQuestions(
			surveySection,
			questionList,
			surveyAnswers
		);
	}

	// MBTI-Q uses AOrB component but the survey schema structure is different so the
	// ISI (Influence Style Indicator) has to be handled differently.
	if (
		surveySection.component === "SurveyAOrBQuestionsComponent" &&
		(instrumentId === 1897 || instrumentId === 1923)
	) {
		if (instrumentId === 1897 || instrumentId === 1923) {
			return getUnansweredAOrBQuestionsUsingOptions(
				surveySection,
				questionList,
				surveyAnswers
			);
		} else {
			return getUnansweredAOrBQuestions(
				surveySection,
				questionList,
				surveyAnswers
			);
		}
	}

	if (surveySection.component === "SurveyMultiDropdownQuestionsComponent") {
		return getUnansweredMultiDropdownQuestions(
			surveySection,
			questionList,
			surveyAnswers
		);
	}

	if (surveySection.component === "SurveyScalesQuestionsComponent") {
		return getUnansweredSurveyScalesQuestions(
			surveySection,
			questionList,
			surveyAnswers
		);
	}

	// default processing for all other questions
	return getOtherUnansweredQuestions(surveySection,
		questionList,
		surveyAnswers);
};

const getOtherUnansweredQuestions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			(questionItem: QuestionListItem) =>
				questionItem.sectionId === surveySection.sectionId &&
				(!questionItem.answer || questionItem.answer === null)
		).filter((questionListItem: QuestionListItem) => {
			// check for questions with conditional validations
			let answered = false;
			if ((questionListItem?.question?.component === "TextInputComponent" || questionListItem?.question?.component === "SingleSelectComponent" || questionListItem?.question?.component === "MultiSelectComponent") && questionListItem?.question?.conditionalValidators !== null) {
				let conditionalValidation = questionListItem?.question?.conditionalValidators;
				conditionalValidation.map(item => {
					// find the parent question that has the conditional selection
					let requiredQuestion = questionList.filter(a => a.question?.formItemSequenceNumber === item.formItemSequenceNumber && a.sectionId === questionListItem.sectionId);

					if (requiredQuestion.length > 0) {
						// get the answers for this parent question
						let latestAnswers = surveyAnswers.filter(b => b.formItemId === requiredQuestion[0].formItemId);
						let answer = latestAnswers.length > 0 ? latestAnswers[latestAnswers.length - 1].answer : requiredQuestion[0].answer;

						// check if the answer falls under one of those
						if (answer && item.answers?.includes(answer)) {
							if (questionListItem.question?.optional) {
								answered = false;
								return answered;
							}
							else {
								// the question is not optional here
								let answerA = getSelectedAnswer(
									questionListItem?.question?.formItemId ?? 0,
									surveyAnswers
								);
								answered = !answerA || answerA === null || answerA === "";
							}

						}
					}
				})
				return answered;
			}
			else {
				return questionList
					.filter(
						(questionListItem: QuestionListItem) =>
							questionListItem.sectionId === surveySection.sectionId &&
							(!questionListItem.answer || questionListItem.answer === null)
					)
					.map((questionListItem: QuestionListItem) => questionListItem.question);
			}
		})
		.map((questionListItem: QuestionListItem) => questionListItem.question);
}

const getUnansweredDemographicQuestions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	let questions: Question[] = [];

	surveySection?.questionGroups?.forEach((questionGroup: QuestionGroup) => {
		questionGroup.questions.forEach((question: Question, index: number) => {
			switch (question.component) {
				case "MultiOptionsComponent":
					{
						if (!multiOptionQuestionAnswered(question, questionList)) {
							questions.push({
								...question,
								number: (index + 1).toString(),
							});
						}
					}
					break;
				case "SingleOptionComponent":
					{
						if (!singleOptionQuestionAnswered(question, questionList)) {
							questions.push({
								...question,
								number: (index + 1).toString(),
							});
						}
					}
					break;
			}
		});
	});
	return questions;
};

const getUnansweredAAndBQuestions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			const questionA = questionList.find(
				(questionAListItem: QuestionListItem) =>
					questionAListItem.formItemId ===
					questionListItem.question!.options![0].formItemId
			);
			const questionB = questionList.find(
				(questionBListItem: QuestionListItem) =>
					questionBListItem.formItemId ===
					questionListItem.question!.options![1].formItemId
			);

			let answerA = getSelectedAnswer(
				questionA?.formItemId ?? 0,
				surveyAnswers
			);
			let answerB = getSelectedAnswer(
				questionB?.formItemId ?? 0,
				surveyAnswers
			);

			return (
				!answerA ||
				answerA === null ||
				answerA === "Select a response" ||
				!answerB ||
				answerB === null ||
				answerB === "Select a response"
			);
		})
		.map((questionListItem: QuestionListItem) => questionListItem.question);
};

const multiOptionQuestionAnswered = (
	question: Question,
	questionList: QuestionListItem[]
) => {
	let answered: boolean = false;

	// if the multi option question is required see if there is at least one checked
	if (question !== undefined && question.optional === false) {
		for (let i = 0; i < question.options!.length; i++) {
			if (
				questionList.some(
					(questionListItem: QuestionListItem) =>
						questionListItem.formItemId === question.options![i].formItemId &&
						questionListItem.answer === "1"
				)
			) {
				answered = true;
				break;
			}
		}
	} else {
		// if optional there is no need to check if anything is checked
		answered = true;
	}

	// always check if there is a dependentOption that is required and
	// if parent is checked ensure the dependentOption has an answer
	let option = question?.options?.find(
		(option: Option) =>
			option.dependentOption && option.dependentOption.optional === false
	);
	if (option) {
		answered =
			answered &&
			(questionList.some(
				(questionListItem: QuestionListItem) =>
					questionListItem.formItemId === option!.formItemId &&
					(questionListItem.answer === null || questionListItem.answer === "0")
			) ||
				(questionList.some(
					(questionListItem: QuestionListItem) =>
						questionListItem.formItemId === option!.formItemId &&
						questionListItem.answer === "1"
				) &&
					questionList.some(
						(questionListItem: QuestionListItem) =>
							questionListItem.formItemId ===
							option!.dependentOption.formItemId &&
							questionListItem.answer !== null && questionListItem.answer !== ""
					)));
	}

	return answered;
};

const singleOptionQuestionAnswered = (
	question: Question,
	questionList: QuestionListItem[]
) => {
	let answered: boolean = false;

	// if the multi option question is required see if there is at least one checked
	if (question.optional === false) {
		for (let i = 0; i < question.options!.length; i++) {
			if (
				questionList.some(
					(questionListItem: QuestionListItem) =>
						questionListItem.formItemId === question.formItemId &&
						questionListItem.answer !== null
				)
			) {
				answered = true;
				break;
			}
		}
	} else {
		// if optional there is no need to check if anything is checked
		answered = true;
	}

	return answered;
};

const getNotObservedQuestions = (
	instrumentId: number,
	surveySection: SurveySection,
	questionList: QuestionListItem[]
) => {
	// return empty array for competencies because missed questions make no sense
	if (surveySection.component === "SurveyCompetenciesQuestionsComponent") {
		return [];
	}

	if (instrumentId === 1927) {
		return questionList
			.filter(
				(questionListItem: QuestionListItem) =>
					questionListItem.sectionId === surveySection.sectionId &&
					questionListItem.answer &&
					questionListItem.answer === "6" &&
					instrumentId === 1927
			)
			.map((questionListItem: QuestionListItem) => questionListItem.question);
	}

	return questionList
		.filter(
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.answer
		)
		.map((questionListItem: QuestionListItem) => questionListItem.question);
};

const getAnswerCount = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	instrumentId: number,
	surveyAnswers: SurveyAnswer[]
) => {
	let ansCount = 0;

	switch (surveySection.component) {
		case "SurveyWelcomeComponent":
			ansCount = getWelcomeAnswerCount(surveySection, surveyAnswers);
			break;

		case "SurveyDemographicsQuestionsComponent":
			if (instrumentId === 1920 || instrumentId === 1860) {
				let question = questionList
					.find(
						(questionListItem: QuestionListItem) =>
							questionListItem.sectionId === surveySection.sectionId &&
							questionListItem.question?.options &&
							questionListItem.question?.options?.find(
								(a) => a.dependentOption !== null && a.dependentOption.answer !== null &&
									a.dependentOption.answer?.trim() !== ""
							)
					)?.question;
				let answered = false;
				let answer = "";
				let optionAnswer = "";
				let options = question?.options?.filter(a => a.dependentOption !== null);
				if (options) {
					options.map(option => {
						if (option.dependentOption.optional === false) {
							let latestAnswers = surveyAnswers.filter(x => x.formItemId === option.formItemId);
							optionAnswer = latestAnswers.length > 0 ? latestAnswers[latestAnswers.length - 1].answer : option.answer;

							if (optionAnswer === "1") {
								answer = getSelectedAnswer(
									option.dependentOption?.formItemId ?? 0,
									surveyAnswers
								);
								if (answer !== null && answer !== "") {
									answered = true;
								}
								else {
									// this is required but not answered
									answered = false;
								}
							}
							else {
								// this is optional and not answered
								ansCount = -1;
								answered = true;
							}
						}
						else {
							// this is optional and not answered
							ansCount = -1;
							answered = true;
						}
					})
				}
				else {
					answered = true;
				}

				if (answered) {
					if (ansCount === -1) {
						// unanswered optional question
						ansCount = 1;
					}
					else {
						ansCount = getDemographicsAnswerCount(
							surveySection,
							questionList,
							surveyAnswers
						);
					}
				}
				else {
					ansCount = 0;
				}
			}
			else {
				ansCount = getDemographicsAnswerCount(
					surveySection,
					questionList,
					surveyAnswers
				);
			}
			break;

		// AAndB question demand special attention because their answer form ID are in
		// the questions options collection.
		case "SurveyAAndBQuestionsComponent":
			ansCount = getAAndBQuestionsAnswerCount(
				surveySection,
				questionList,
				surveyAnswers
			);
			break;

		// MBTI-Q uses AOrB component but the survey schema structure is different so the
		// ISI (Influence Style Indicator) / TKI (Thomas-Kilmann Conflict Mode Instrument) has to be handled differently.
		case "SurveyAOrBQuestionsComponent": //(instrumentId === 1897 || instrumentId === 1923)
			if (instrumentId === 1897 || instrumentId === 1923) {
				ansCount = getAOrBQuestionsAnswerCountUsingOptions(
					surveySection,
					questionList,
					surveyAnswers
				);
			} else {
				ansCount = getAOrBQuestionsAnswerCount(
					surveySection,
					questionList,
					surveyAnswers
				);
			}
			break;

		case "SurveyMultiDropdownQuestionsComponent":
			ansCount = getMultiDropdownQuestionsAnswerCount(
				surveySection,
				questionList,
				surveyAnswers
			);
			break;

		case "SurveyScalesQuestionsComponent":
			ansCount = getSurveyScalesQuestionsAnswerCount(
				surveySection,
				questionList,
				surveyAnswers
			);
			break;

		case "SurveyCompetenciesQuestionsComponent":
			ansCount = getCompetenciesQuestionsAnswerCount(
				surveySection,
				questionList,
				surveyAnswers
			);
			break;

		default:
			// default processing for all other questions
			ansCount = questionList.filter(
				(questionListItem: QuestionListItem) =>
					questionListItem.sectionId === surveySection.sectionId &&
					questionListItem.answer &&
					questionListItem.answer !== null
			).length;
			break;
	}

	return ansCount;
};

const getWelcomeAnswerCount = (
	surveySection: SurveySection,
	surveyAnswers: SurveyAnswer[]
) => {
	return isUnderstood(surveySection, surveyAnswers) ? 1 : 0;
};

const isUnderstood = (
	welcomeSection: SurveySection,
	surveyAnswers: SurveyAnswer[]
) => {
	if (welcomeSection.optional) {
		return true;
	}

	if (
		welcomeSection?.questionGroups?.length === 0 ||
		welcomeSection.questionGroups[0].questions.length === 0
	) {
		return true;
	}

	let iUnderstandQuestion: Question =
		welcomeSection.questionGroups[0].questions[0];

	let answer = getSelectedAnswer(iUnderstandQuestion.formItemId, surveyAnswers);
	let iUnderstandAnswers: SurveyAnswer[] = surveyAnswers.filter(
		(surveyAnswer: SurveyAnswer) =>
			surveyAnswer.formItemId === iUnderstandQuestion.formItemId &&
			answer &&
			answer === "1"
	);

	return iUnderstandAnswers.length > 0;
};

const getDemographicsAnswerCount = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	let answered: number = 0;

	surveySection?.questionGroups?.forEach((questionGroup: QuestionGroup) => {
		questionGroup.questions.forEach((question: Question) => {
			switch (question.component) {
				case "MultiOptionsComponent":
					{
						if (multiOptionQuestionAnswered(question, questionList)) {
							answered++;
						}
					}
					break;
				case "SingleOptionComponent":
					{
						if (singleOptionQuestionAnswered(question, questionList)) {
							answered++;
						}
					}
					break;
			}
		});
	});
	return answered;
};

const getAAndBQuestionsAnswerCount = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			// A AND B question answers are stored with the each question option, so we have to find them and their answers.
			const questionA = questionList.find(
				(questionAListItem: QuestionListItem) =>
					questionAListItem.formItemId ===
					questionListItem.question!.options![0].formItemId
			);
			const questionB = questionList.find(
				(questionBListItem: QuestionListItem) =>
					questionBListItem.formItemId ===
					questionListItem?.question!.options![1].formItemId
			);

			let answerA = getSelectedAnswer(
				questionA?.formItemId ?? 0,
				surveyAnswers
			);
			let answerB = getSelectedAnswer(
				questionB?.formItemId ?? 0,
				surveyAnswers
			);
			return answerA && answerA !== null && answerB && answerB !== null;
		}).length;
};

const getCompetenciesQuestionsAnswerCount = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.answer
		)
		.filter((questionListItem: QuestionListItem) => {
			let answer = getSelectedAnswer(
				questionListItem?.formItemId ?? 0,
				surveyAnswers
			);

			return answer && (answer !== null && answer !== " ") && answer === "1";
		}).length;
};

const getAOrBQuestionsAnswerCount = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			// A OR B question answers are stored with the top-level question.
			let answer = getSelectedAnswer(
				questionListItem?.question?.formItemId ?? 0,
				surveyAnswers
			);
			return answer && answer !== null;
		}).length;
};

const getAOrBQuestionsAnswerCountUsingOptions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			// A OR B question answers are stored with the each question option, so we have to find them and their answers.
			const questionA = questionList.find(
				(questionAListItem: QuestionListItem) =>
					questionAListItem.formItemId ===
					questionListItem.question!.options![0].formItemId
			);
			const questionB = questionList.find(
				(questionBListItem: QuestionListItem) =>
					questionBListItem.formItemId ===
					questionListItem?.question!.options![1].formItemId
			);

			let answerA = getSelectedAnswer(
				questionA?.formItemId ?? 0,
				surveyAnswers
			);
			let answerB = getSelectedAnswer(
				questionB?.formItemId ?? 0,
				surveyAnswers
			);
			return answerA && answerA !== null && answerB && answerB !== null;
		}).length;
};

const getMultiDropdownQuestionsAnswerCount = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			let answers: Array<string> = [];

			questionListItem.question!.options!.forEach((option: Option) => {
				const question = questionList.find(
					(questionListItem: QuestionListItem) =>
						questionListItem.formItemId === option.formItemId
				);
				let answer = getSelectedAnswer(
					question?.formItemId ?? 0,
					surveyAnswers
				);
				if (answer) {
					answers.push(answer);
				}
			});

			return (
				answers.length === 4 && answers.every((e, i, a) => a.indexOf(e) === i)
			);
		}).length;
};

const getSurveyScalesQuestionsAnswerCount = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	let f1 = questionList.filter(
		// filter the parent question so we can get to the options
		(questionListItem: QuestionListItem) =>
			questionListItem.sectionId === surveySection.sectionId &&
			questionListItem.question &&
			questionListItem.question !== null
	);
	let f2 = f1.filter((questionListItem: QuestionListItem) => {
		let answers: Array<string> = [];

		if (questionListItem.question!.hasOptions) {
			questionListItem.question!.options!.forEach((option: Option) => {
				const question = questionList.find(
					(questionListItem: QuestionListItem) =>
						questionListItem.formItemId === option.formItemId
				);
				let answer = getSelectedAnswer(
					question?.formItemId ?? 0,
					surveyAnswers
				);
				if (answer) {
					answers.push(answer);
				}
			});
			return answers.length === questionListItem.question!.options!.length;
		} else {
			let answer = getSelectedAnswer(
				questionListItem.formItemId,
				surveyAnswers
			);
			if (answer && answer !== null) {
				answers.push(answer);
				return answers;
			}
		}
	});

	let filteredList = questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			let answers: Array<string> = [];

			if (questionListItem.question!.hasOptions) {
				questionListItem.question!.options!.forEach((option: Option) => {
					const question = questionList.find(
						(questionListItem: QuestionListItem) =>
							questionListItem.formItemId === option.formItemId
					);
					let answer = getSelectedAnswer(
						question?.formItemId ?? 0,
						surveyAnswers
					);
					if (answer) {
						answers.push(answer);
					}
				});
				return answers.length === questionListItem.question!.options!.length;
			} else {
				let answer = getSelectedAnswer(
					questionListItem.formItemId,
					surveyAnswers
				);
				if (answer && answer !== null) {
					answers.push(answer);
					return answers;
				}
			}
		});
	return filteredList.length;
};

const getUnansweredCompetenciesQuestions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId
		)
		.filter((questionListItem: QuestionListItem) => {
			let answer = getSelectedAnswer(
				questionListItem?.formItemId ?? 0,
				surveyAnswers
			);

			return (answer && answer === " ") || answer === null || answer === "";
		})
		.map((questionListItem: QuestionListItem) => questionListItem.question);
};

const getUnansweredAOrBQuestions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			// A OR B question answers are stored with the top-level question.
			let answer = getSelectedAnswer(
				questionListItem?.question?.formItemId ?? 0,
				surveyAnswers
			);

			return !answer || answer === null || answer === "";
		})
		.map((questionListItem: QuestionListItem) => questionListItem.question);
};

const getUnansweredAOrBQuestionsUsingOptions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			// A OR B question answers are stored with the each question option, so we have to find them and their answers.
			const questionA = questionList.find(
				(questionAListItem: QuestionListItem) =>
					questionAListItem.formItemId ===
					questionListItem.question!.options![0].formItemId
			);
			const questionB = questionList.find(
				(questionBListItem: QuestionListItem) =>
					questionBListItem.formItemId ===
					questionListItem?.question!.options![1].formItemId
			);

			let answerA = getSelectedAnswer(
				questionA?.formItemId ?? 0,
				surveyAnswers
			);
			let answerB = getSelectedAnswer(
				questionB?.formItemId ?? 0,
				surveyAnswers
			);
			return (
				(answerA === null || answerA === "") &&
				(answerB === null || answerB === "")
			);
		})
		.map((questionListItem: QuestionListItem) => questionListItem.question);
};

const getUnansweredMultiDropdownQuestions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			let answers: any = [];

			questionListItem.question!.options!.forEach((option: Option) => {
				const question = questionList.find(
					(questionListItem: QuestionListItem) =>
						questionListItem.formItemId === option.formItemId
				);

				let answer = getSelectedAnswer(
					question?.formItemId ?? 0,
					surveyAnswers
				);
				if (answer) {
					answers.push(answer);
				}
			});

			return !(
				answers.length === 4 && answers.every((e, i, a) => a.indexOf(e) === i)
			);
		})
		.map((questionListItem: QuestionListItem) => questionListItem.question);
};

const getUnansweredSurveyScalesQuestions = (
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[]
) => {
	return questionList
		.filter(
			// filter the parent question so we can get to the options
			(questionListItem: QuestionListItem) =>
				questionListItem.sectionId === surveySection.sectionId &&
				questionListItem.question &&
				questionListItem.question !== null
		)
		.filter((questionListItem: QuestionListItem) => {
			if (questionListItem && questionListItem?.question?.hasOptions) {
				let answers: any = [];
				questionListItem.question?.options?.forEach((option: Option) => {
					const question = questionList.find(
						(questionListItem: QuestionListItem) =>
							questionListItem.formItemId === option.formItemId
					);
					let answer = getSelectedAnswer(
						question?.formItemId ?? 0,
						surveyAnswers
					);
					if (answer) {
						answers.push(answer);
					}
				});
				return answers.length !== questionListItem.question?.options?.length;
			} else {
				let answered = false;
				if (questionListItem?.question?.component === "TextInputComponent" && questionListItem?.question?.conditionalValidators !== null) {
					let conditionalValidation = questionListItem?.question?.conditionalValidators;
					conditionalValidation.map(item => {
						// find the parent question that has the conditional selection
						let requiredQuestion = questionList.filter(a => a.question?.formItemSequenceNumber === item.formItemSequenceNumber && a.sectionId === questionListItem.sectionId);

						if (requiredQuestion.length > 0) {
							// get the answers for this parent question
							let latestAnswers = surveyAnswers.filter(b => b.formItemId === requiredQuestion[0].formItemId);
							let answer = latestAnswers.length > 0 ? latestAnswers[latestAnswers.length - 1].answer : requiredQuestion[0].answer;

							// check if the answer falls under one of those
							if (answer && item.answers?.includes(answer)) {
								// the question is not optional here
								let answerA = getSelectedAnswer(
									questionListItem?.question?.formItemId ?? 0,
									surveyAnswers
								);
								answered = !answerA || answerA === null || answerA === "";
							}
						}
					})
					return answered;
				}
				return !questionListItem.answer || questionListItem.answer === null;
			}
		})
		.map((questionListItem: QuestionListItem) => questionListItem.question);
};

const isAnswered = (
	instrumentId: number,
	surveySection: SurveySection,
	questionList: QuestionListItem[],
	surveyAnswers: SurveyAnswer[],
	validationRule?: any
) => {
	const engine = new Engine();

	// No validation
	if (surveySection.component === "SurveyReviewQuestionsComponent") {
		return engine.run().then((result) => {
			return result.events.length === 0;
		});
	}

	if (validationRule ?? surveySection.validationRule)
		engine.addRule(validationRule ?? surveySection.validationRule);

	let numAnswered = getAnswerCount(
		surveySection,
		questionList,
		instrumentId,
		surveyAnswers
	);
	let notObsExceeded = questionList.filter(
		(questionListItem: QuestionListItem) =>
			instrumentId === 1927 &&
			questionListItem.sectionId === surveySection.sectionId &&
			questionListItem.answer &&
			questionListItem.answer === "6"
	).length;

	const facts = {
		numberAnswered: numAnswered,
		notObservedExceeded: notObsExceeded,
	};
	return engine.run(facts).then((result) => {
		return result.events.length === 0;
	});
};

const buildSurveySection = (state: SurveyDocumentStateModel) => {
	// determine where to start the survey based on firstUnansweredFormItemId
	let currentSectionIndex: number = 0;

	if (state.surveyDocument.survey.lastAnsweredFormItemId !== 0) {
		const lastAnswered = state.questionList?.find(
			(questionListItem: QuestionListItem) =>
				questionListItem?.formItemId ===
				state?.surveyDocument?.survey?.lastAnsweredFormItemId
		);
		if (lastAnswered) {
			const sectionQuestionList: QuestionListItem[] = state.questionList.filter(
				(questionListItem: QuestionListItem) =>
					questionListItem.sectionId == lastAnswered.sectionId
			);
			currentSectionIndex =
				state.surveyDocument.survey.surveySections.findIndex(
					(surveySection: SurveySection) =>
						surveySection.sectionId === lastAnswered.sectionId
				);

			if (
				sectionQuestionList[sectionQuestionList.length - 1].formItemId ===
				lastAnswered.formItemId
			) {
				currentSectionIndex++;
			}
		}
	}

	return currentSectionIndex;
};

const buildQuestionList = (
	surveySections: SurveySection[],
	surveyAnswers: SurveyAnswer[]
) => {
	let questionList: QuestionListItem[] = [];

	surveySections.forEach((surveySection: SurveySection) => {
		if (!surveySection.questionGroups) return;
		surveySection?.questionGroups?.forEach((questionGroup: QuestionGroup) => {
			if (!questionGroup.questions) return;

			questionGroup.questions.forEach((question: Question) => {
				const answers: SurveyAnswer[] = surveyAnswers
					.filter(
						(surveyAnswer: SurveyAnswer) =>
							surveyAnswer.formItemId === question.formItemId
					)
					.sort(
						(a: SurveyAnswer, b: SurveyAnswer) =>
							b.answeredTimeMs - a.answeredTimeMs
					); // sort in descending order

				questionList.push({
					sectionId: surveySection.sectionId,
					formItemId: question.formItemId,
					formItemSequenceNumber: question.formItemSequenceNumber,
					answer: (answers.length > 0 ? answers[answers.length - 1].answer : null) as string,
					question: question,
				});

				if (question.dependentOption) {
					const answers: SurveyAnswer[] = surveyAnswers
						.filter(
							(surveyAnswer: SurveyAnswer) =>
								surveyAnswer.formItemId === question.dependentOption.formItemId
						)
						.sort(
							(a: SurveyAnswer, b: SurveyAnswer) =>
								b.answeredTimeMs - a.answeredTimeMs
						); // sort in descending order

					questionList.push({
						sectionId: surveySection.sectionId,
						formItemId: question.dependentOption.formItemId ?? 0,
						formItemSequenceNumber:
							question.dependentOption.formItemSequenceNumber ?? 0,
						answer: (answers.length > 0 ? answers[answers.length - 1].answer : null) as string, // JM come check on this use of .answer
						question: question,
					});
				}

				if (
					question.options &&
					question.options.some((option) => !!option.formItemId)
				) {
					question.options.forEach((option) => {
						const answers: SurveyAnswer[] = surveyAnswers
							.filter(
								(surveyAnswer: SurveyAnswer) =>
									surveyAnswer.formItemId === option.formItemId
							)
							.sort(
								(a: SurveyAnswer, b: SurveyAnswer) =>
									b.answeredTimeMs - a.answeredTimeMs
							); // sort in descending order

						questionList.push({
							sectionId: surveySection.sectionId,
							formItemId: option.formItemId ?? 0,
							formItemSequenceNumber: option.formItemSequenceNumber ?? 0,
							answer: (answers.length > 0 ? answers[answers.length - 1].answer : null) as string, // JM come check on this use of .answer
						});

						if (option.dependentFormItemSequenceNumber) {
							const answers: SurveyAnswer[] = surveyAnswers
								.filter(
									(surveyAnswer: SurveyAnswer) =>
										surveyAnswer.formItemId ===
										option.dependentOption.formItemId
								)
								.sort(
									(a: SurveyAnswer, b: SurveyAnswer) =>
										b.answeredTimeMs - a.answeredTimeMs
								); // sort in descending order

							questionList.push({
								sectionId: surveySection.sectionId,
								formItemId: option.dependentOption.formItemId ?? 0,
								formItemSequenceNumber:
									option.dependentOption.formItemSequenceNumber ?? 0,
								answer: (answers.length > 0
									? answers[answers.length - 1].answer // JM come check on this use of .answer
									: null) as string,
							});
						}
					});
				}
			});
		});
	});

	return questionList;
};

const getRequiredAnswersCount = (validationRule: any) => {
	let requiredNoOfAnswers = 0;
	let rule: any = validationRule;

	if (rule?.conditions?.all) {
		requiredNoOfAnswers = validationRule?.conditions?.all[0].value;
	}
	return requiredNoOfAnswers;
};

export const getSection = (
	sectionIndex: number,
	activeSurvey: SurveyResponse
) => {
	if (
		sectionIndex >= 0 &&
		sectionIndex < activeSurvey.surveyDocument.survey.surveySections.length
	) {
		return activeSurvey.surveyDocument.survey.surveySections[sectionIndex];
	} else {
		return activeSurvey.surveyDocument.survey.surveySections[0];
	}
};

export {
	getSurveyReviewCards,
	isAnswered,
	buildSurveySection,
	buildQuestionList,
	getAnswerCount,
	getRequiredAnswersCount,
};
