import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { keyframes } from "@emotion/react";

import {
	Button,
	Card,
	CardContent,
	Grid,
	Typography,
	Stack,
	Box,
	IconButton,
} from "@mui/material";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

import {
	DocumentInfo,
	InstrumentStatus,
	NavigationModes,
	ProgramStatus,
	ScaleDisplayModes,
} from "../../@types/surveys.d";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { DisplayWarnings } from "./DisplayWarnings";
import { Loading } from "../../components";

import {
	fetchSurvey,
	setInstrumentId,
	setLoading,
	setParticipantId,
	setRaterInformationForRaterSurvey,
	setSelectedSurveyType,
	setSurveyReopened,
} from "../../store/User/UserSlice";
import { useNavigate } from "react-router-dom-v5-compat";

import { RootState } from "../../store";
import { useEnv } from "../../context/env.context";

import { FormattedPhrase } from "../../context/FormattedPhrase";
import { useTranslationContext } from "../../context/TranslationContext";
import "../HomePage.scss";
import { getAsset, getDocument } from "../../services/apiService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { VideoModal } from "../../components/shared/videoModal";
import { setShowToast } from "../../store/Navigation/NavSlice";

type InstrumentStatusProps = {
	screenReaderTitle?: string;
	screenReaderDescription?: string;
	participantId?: number;
	instrumentId?: number;
	statusId?: number;
	surveyTypeId?: number;
	instrumentStatus?: InstrumentStatus;
	category: string;
	programStatus?: boolean;
	disable?: boolean;
	assetAvailable?: boolean;
	surveyPlatform?: string;
};

export const DisplayInstramentLink: React.FC<InstrumentStatusProps> = ({
	screenReaderTitle,
	screenReaderDescription,
	instrumentStatus,
	participantId,
	instrumentId,
	statusId,
	surveyTypeId,
	category,
	assetAvailable,
	surveyPlatform
}) => {
	const dispatch = useDispatch();
	const { user } = useAuth0();
	const theme = useTheme();
	const { domain, webAppUrl } = useEnv();

	const { getFormattedPhrase } = useTranslationContext();

	const [viewLabel, setViewLabel] = useState<string>("");

	const heartbeatAnimation = keyframes`
	0% {
		transform: scale(1);
	}
	30% {
		transform: scale(1.2);
	}
	50% {
		transform: scale(1);
	}
	70% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}`;

	const burstAnimation = keyframes`
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}`;

	useEffect(() => {
		let phrase = getFormattedPhrase("participant.button.viewCompletedAssessment");
		setViewLabel(phrase);
	}, []);

	const { selectedLanguage, selectedSurveyLanguage, programRaterStatuses } = useSelector((state: RootState) => state.user);

	useEffect(() => {
		if (surveyPlatform) {
			// console.log(surveyPlatform);
		}
	}, [selectedLanguage, surveyPlatform])

	const navigate = useNavigate();

	const getFontSize = (textType: number) => {
		let linkText = ((window.innerWidth / 65) > 18 ? "unset" : window.innerWidth / 70);

		switch (textType) {
			case 1: return linkText;
		}
	}

	const renderActions = (assessmentName: string) => {
		let accessibilityText = screenReaderTitle ?? assessmentName;
		let buttonText = "";
		let linkText = "";
		switch (statusId) {
			case 0:
			case 1:
				buttonText = getFormattedPhrase("participant.button.beginAssessment");
				//category === "assessment" ? "Begin Assessment" : "Start";
				return (
					<Stack direction="row-reverse" mt={-1}>
						<Link
							to="#"
							aria-label={buttonText + " " + accessibilityText}
							style={{
								flexDirection: "row-reverse",
								textDecoration: "none",
								color:
									category === "About Me"
										? theme.palette.primary.main
										: "#ffffff",
							}}
							onClick={() => {
								if (instrumentStatus?.instrumentTypeId === 6 && instrumentStatus?.assessmentLinkInfo?.assessmentLink) {
									window.open(instrumentStatus?.assessmentLinkInfo?.assessmentLink, "_blank")
								}
								else {
									startOrContinueSurvey()
								}
							}}
						>
							<Button
								variant="contained"
								sx={{
									fontWeight: 500,
									fontSize: "0.938rem",
									borderRadius: "100px",
									backgroundColor: theme.palette.secondary.contrastText,
									color: theme.palette.primary.main,
									borderColor: theme.palette.primary.main,
									borderWidth: 1,
									borderStyle: "solid",
									// animation: `${heartbeatAnimation} 1.5s ease-in-out 2`,
									"&:hover": {
										backgroundColor: "#EAEFF4",
										color: theme.palette.primary.main,
									}
								}}
								className="btn btn-outline-primary"
							>
								{buttonText}
								{
									(instrumentStatus?.instrumentTypeId === 6) &&
									<OpenInNewIcon
										sx={{
											color: theme.palette.primary.main,
											ml: 2,
											mb: 1,
											fontSize: 22,
										}}
									/>
								}
							</Button>
						</Link>
					</Stack>
				);

			case 2:
				// buttonText = "Mark Complete";
				linkText = getFormattedPhrase("participant.button.continueAssessment");
				return (
					<Stack direction="row-reverse" mt={-1}>
						<Link
							aria-label={linkText + " " + accessibilityText}
							to="#"
							className="link-style"
							style={{ textDecoration: "none" }}
							onClick={() => {
								if (instrumentStatus?.instrumentTypeId === 6 && instrumentStatus?.assessmentLinkInfo?.assessmentLink) {
									window.open(instrumentStatus?.assessmentLinkInfo?.assessmentLink, "_blank")
								}
								else {
									startOrContinueSurvey()
								}
							}}
						>
							<Button
								variant="outlined"
								aria-label={linkText + " " + accessibilityText}
								sx={{
									fontWeight: 500,
									fontSize: "0.938rem",
									borderRadius: "100px",
									alignContent: "center",
									alignItems: "center",
									height: 35,
									backgroundColor: theme.palette.secondary.contrastText,
									borderColor: theme.palette.primary.main,
									borderWidth: 1,
									color: theme.palette.primary.main,
									// animation: `${heartbeatAnimation} 1.5s ease-in-out 2`,
									"&:hover": {
										backgroundColor: "#EAEFF4",
										color: theme.palette.primary.main,
									}
								}}
								className="btn btn-outline-primary"
							>
								{linkText}
								{
									(instrumentStatus?.instrumentTypeId === 6) &&
									<OpenInNewIcon
										sx={{
											color: theme.palette.primary.main,
											ml: 2,
											fontSize: 22,
										}}
									/>
								}
							</Button>
						</Link>
					</Stack>
				);

			case 3:
				buttonText = viewLabel;
				if (assetAvailable) {
					let imKey = 0;
					if (user && instrumentStatus?.assetAvailable) {
						imKey =
							user[
							"http://schemas.ccl.org/accounts/claims/ccl-identity/id"
							];
					}
					return (
						<Stack direction="row-reverse" mt={-1}>
							<Link
								aria-label={viewLabel + " " + accessibilityText}
								to={`/report?id=${imKey}&pid=${instrumentStatus?.assetInfo?.assetInfoId}&did=${instrumentStatus?.assetInfo?.latestVersion}&dName=null&type=asset`}
								className="link-style"
								style={{ textDecoration: "none", flexDirection: "row", display: "flex" }}
								onClick={() => {
									if (instrumentStatus?.instrumentTypeId === 6 && instrumentStatus?.assessmentLinkInfo?.reportLink) {
										window.open(instrumentStatus?.assessmentLinkInfo?.reportLink, "_blank")
									}
								}}
							>
								<Button
									variant="outlined"
									aria-label={linkText + " " + accessibilityText}
									sx={{
										fontWeight: 500,
										fontSize: "0.938rem",
										borderRadius: "100px",
										alignContent: "center",
										alignItems: "center",
										height: 35,
										backgroundColor: theme.palette.secondary.contrastText,
										borderColor: theme.palette.primary.main,
										borderWidth: 1,
										color: theme.palette.primary.main,
										// animation: `${heartbeatAnimation} 1.5s ease-in-out 2`,
										"&:hover": {
											backgroundColor: "#EAEFF4",
											color: theme.palette.primary.main,
										}
									}}
									className="btn btn-outline-primary"
								>
									<Typography
										variant="subtitle1"
										sx={{
											color: theme.palette.primary.main,
											fontWeight: 500,
											fontSize: "0.938rem",
										}}
									>
										{getFormattedPhrase("participant.button.viewCompletedAssessment")}
									</Typography>
								</Button>
								{
									(instrumentStatus?.instrumentTypeId === 6 && instrumentStatus?.assessmentLinkInfo?.reportLink) &&
									<OpenInNewIcon
										sx={{
											color: theme.palette.primary.main,
											ml: 2,
											mt: 0.5,
											fontSize: 21,
										}}
									/>
								}
							</Link>
						</Stack>
					)
				} else if (surveyTypeId === 0 || category === "About Me") {
					return (
						<Stack direction="row-reverse" mt={-1}>
							<Link
								aria-label={buttonText + " " + accessibilityText}
								to="#"
								className="link-style"
								style={{ textDecoration: "none" }}
								onClick={() => startOrContinueSurvey()}
							>
								{category === "assessment" ? (
									<Button
										variant="outlined"
										sx={{
											fontWeight: 500,
											borderRadius: "100px",
											alignContent: "center",
											height: 35,
											fontSize: "0.938rem",
											"&:hover": {
												backgroundColor: "#EAEFF4",
												color: theme.palette.primary.main,
											}
										}}
										className="btn btn-outline-primary"
									>
										<Typography
											variant="subtitle1"
											sx={{
												color: theme.palette.primary.main,
												fontWeight: 500,
											}}
										>
											{getFormattedPhrase("surveys.review.complete")}
										</Typography>
									</Button>
								) : (
									<Button
										variant="outlined"
										sx={{
											fontWeight: 500,
											borderRadius: "100px",
											alignContent: "center",
											height: 35,
											backgroundColor: theme.palette.secondary.contrastText,
											borderColor: theme.palette.primary.main,
											borderWidth: 1,
											fontSize: "0.938rem",
											color: theme.palette.primary.main,
											"&:hover": {
												backgroundColor: "#EAEFF4",
												color: theme.palette.primary.main,
											}
										}}
										className="btn btn-outline-primary"
									>
										<FormattedPhrase id="navigation.review" />
									</Button>
								)}
							</Link>
						</Stack>
					);
				} else if (instrumentStatus?.instrumentTypeId === 6) {
					return (
						<Stack direction="row-reverse" mt={-1}>
							<Link
								to="#"
								style={{ textDecoration: "none", marginRight: 20, flexDirection: "row", display: "flex" }}
								onClick={() => {
									if (instrumentStatus?.instrumentTypeId === 6 && instrumentStatus?.assessmentLinkInfo?.reportLink) {
										window.open(instrumentStatus?.assessmentLinkInfo?.reportLink, "_blank")
									}
								}}
							>
								<Typography
									variant="subtitle1"
									sx={{
										color: theme.palette.primary.main,
										fontWeight: 500,
										fontSize: "0.938rem",
									}}
								>
									{getFormattedPhrase("participant.button.view")}
								</Typography>
								{
									(instrumentStatus?.instrumentTypeId === 6 && instrumentStatus?.assessmentLinkInfo?.reportLink) &&
									<OpenInNewIcon
										sx={{
											color: theme.palette.primary.main,
											ml: 2,
											mt: 0.5,
											fontSize: 21,
										}}
									/>
								}
							</Link>
						</Stack>
					)
				} else {
					return (
						<Stack direction="row-reverse" mt={-1}>
							<CheckCircleIcon
								sx={{
									color: "#4B7E37",
									marginTop: -2,
									marginLeft: -2,
									fontSize: 20,
								}}
							/>
						</Stack>
					);
				}

			case 4:
				if (surveyTypeId === 0) {
					return (
						<Stack direction="row-reverse" mt={-1}>
							{category === "assessment" ? (
								<Stack>
									<Link
										to="#"
										className="link-style"
										style={{ textDecoration: "none" }}
									>
										<Button
											variant="outlined"
											sx={{
												fontWeight: 500,
												borderRadius: "100px",
												alignContent: "center",
												height: 35,
												fontSize: "0.938rem",
												"&:hover": {
													backgroundColor: "#EAEFF4",
													color: theme.palette.primary.main,
												}
											}}
											className="btn btn-outline-primary"
										>
											<Typography
												variant="subtitle1"
												sx={{
													color: theme.palette.primary.main,
													fontWeight: 500,
												}}
											>
												{buttonText}
											</Typography>
										</Button>
									</Link>
								</Stack>
							) : (
								<Button
									variant="outlined"
									sx={{
										fontWeight: 500,
										borderRadius: "100px",
										alignContent: "center",
										height: 35,
										fontSize: "0.938rem",
										"&:hover": {
											backgroundColor: "#EAEFF4",
											color: theme.palette.primary.main,
										}
									}}
									className="btn btn-outline-primary"
								>
									<FormattedPhrase id="navigation.review" />
								</Button>
							)}
						</Stack>
					);
				} else {
					return (
						<Stack direction="row-reverse" mt={-1}>
							<CheckCircleIcon
								sx={{
									color: "#4B7E37",
									marginTop: -2,
									marginLeft: -2,
									fontSize: 20,
								}}
							/>
						</Stack>
					);
				}
		}

		let imKey = 0;
		if (user && instrumentStatus?.assetAvailable) {
			imKey =
				user[
				"http://schemas.ccl.org/accounts/claims/ccl-identity/id"
				];
		}
		return (
			<Stack direction="row-reverse" mt={-1}>
				{category === "About Me" ? (
					<Link
						to={`/aboutme?id=${instrumentId}&pid=${participantId}`}
						className="link-style"
					>
						<Button
							variant="outlined"
							sx={{
								borderRadius: "100px",
								alignContent: "center",
								height: 35,
								fontSize: "0.938rem",
								"&:hover": {
									backgroundColor: "#EAEFF4",
									color: theme.palette.primary.main,
								},
								// animation: `${burstAnimation} 0.6s ease-out 2`,
							}}
							className="btn btn-outline-primary"
						>
							<Typography
								variant="subtitle1"
								sx={{
									color: theme.palette.primary.main,
									fontWeight: 500,
								}}
							>
								{viewLabel}
							</Typography>
						</Button>
					</Link>
				) : (
					<Link
						to={`/report?id=${imKey}&pid=${instrumentStatus?.assetInfo?.assetInfoId}&did=${instrumentStatus?.assetInfo?.latestVersion}&dName=null&type=asset`}
						style={{ textDecoration: "none" }}
						onClick={() => {
							if (instrumentStatus?.instrumentTypeId === 6 && instrumentStatus?.assessmentLinkInfo?.reportLink) {
								window.open(instrumentStatus?.assessmentLinkInfo?.reportLink, "_blank")
							}
						}}
					>
						<Button
							variant="outlined"
							sx={{
								fontWeight: 500,
								borderRadius: "100px",
								alignContent: "center",
								height: 35,
								"&:hover": {
									backgroundColor: "#EAEFF4",
									color: theme.palette.primary.main,
								},
								// animation: `${burstAnimation} 0.6s ease-out`,
							}}
							className="btn btn-outline-primary"
						>
							<Typography
								variant="subtitle1"
								sx={{
									color: theme.palette.primary.main,
									fontWeight: 500,
								}}
							>
								{getFormattedPhrase("participant.button.view")}
							</Typography>
						</Button>
						{
							(instrumentStatus?.instrumentTypeId === 6 && instrumentStatus?.assessmentLinkInfo?.reportLink) &&
							<OpenInNewIcon
								sx={{
									color: theme.palette.primary.main,
									ml: 2,
									mt: 0.5,
									fontSize: 21,
								}}
							/>
						}
					</Link>
				)}
			</Stack>
		);
	};

	const startOrContinueSurvey = async () => {
		// route to AP1 for old surveys
		let redirectUrl = "";
		if (instrumentStatus || surveyPlatform) {
			if (domain === "auth-dev.ccl.org") {
				redirectUrl = "https://assessments1-dev.ccl.org";
			}
			else if (domain === "auth-qa.ccl.org") {
				redirectUrl = "https://assessments1-qa.ccl.org";
			}
			else if (domain === "auth-uat.ccl.org") {
				redirectUrl = "https://assessments1-uat.ccl.org";
			}
			else if (domain === "auth.ccl.org") {
				redirectUrl = "https://assessments1.ccl.org";
			}
			if ((instrumentStatus?.surveyPlatform === "AP1" || (surveyPlatform === "AP1")) && ((instrumentStatus && instrumentStatus?.selfStatus.statusId < 3) || (statusId !== undefined && statusId < 3))) {
				if (webAppUrl) {
					window.location.href = `${redirectUrl}?id=${instrumentId}&pid=${participantId}&type=${surveyTypeId}&retUrl=${webAppUrl}`;
				}
				return;
			}
		}
		// For WPB5, we just use the selected survey language.
		let requestData = {
			user: user,
			instrumentId: instrumentId,
			participantId: participantId,
			languageId: instrumentId === 2946 ? 0 : (instrumentId === 2947 ? selectedSurveyLanguage?.languageId : (statusId !== undefined && statusId > 0) ? selectedSurveyLanguage?.languageId : selectedLanguage?.languageId),
			surveyTypeId: surveyTypeId,
		};
		dispatch(fetchSurvey(requestData));
		dispatch(setInstrumentId(instrumentId ?? 0));
		dispatch(setSelectedSurveyType(surveyTypeId ?? 0));
		dispatch(setParticipantId(participantId ?? 0));
		dispatch(setSurveyReopened(false));

		//the following state variable sets (or resets) the rater's participant details to show when the flow is a rater survey, to show on the survey pages
		dispatch(setRaterInformationForRaterSurvey(programRaterStatuses?.surveyStatuses?.filter((p) => (p.instrument.instrumentId === instrumentId && p.participant.participantId === participantId))[0] ?? {}))
		navigate("/survey");
	};

	if (category === "About Me") {
		return (
			<Fragment>
				{renderActions(
					getFormattedPhrase("participant.aboutMe.assessmentName")
				)}
			</Fragment>
		);
	} else {
		return <Box sx={{ flex: 1, marginTop: 1 }}>{renderActions("")}</Box>;
	}
};

type DocumentProps = {
	documentInfo: DocumentInfo;
	participantId: number;
	individualId: number;
	instrumentStatus?: InstrumentStatus;
};

export const DisplayDocumentStatus: React.FC<DocumentProps> = ({
	documentInfo,
	participantId,
	individualId,
	instrumentStatus
}) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const { getFormattedPhrase } = useTranslationContext();
	const { loading } = useSelector((state: RootState) => state.user);

	const viewLabel = getFormattedPhrase("participant.button.view");

	const navigate = useNavigate();

	const renderActions = () => {
		return (
			<Stack direction="row-reverse">
				<Link
					to={`/report?id=${individualId}&pid=${participantId}&did=${documentInfo?.documentId}&dName=${instrumentStatus?.documentInfo?.name}&type=document`}
					style={{ textDecoration: "none" }}
				>
					<Button
						variant="outlined"
						sx={{
							fontWeight: 500,
							borderRadius: "100px",
							alignContent: "center",
							height: 35,
							fontSize: "0.938rem"
						}}
						className="btn btn-outline-primary"
					>
						<Typography
							variant="subtitle1"
							sx={{
								color: theme.palette.primary.main,
								fontWeight: 500,
							}}
						>
							{viewLabel}
						</Typography>
					</Button>
				</Link>
			</Stack>
		);
	};

	return (
		<Box sx={{ flex: 1, mt: -1 }}>
			{loading && <Loading />}
			{renderActions()}
		</Box>
	);
};

export const DisplayInstrumentStatus: React.FC<InstrumentStatusProps> = ({
	instrumentStatus,
	category,
	programStatus,
	surveyTypeId,
}) => {
	const theme = useTheme();
	const [videoPlayerOpen, setVideoPlayerOpen] = useState("");
	const [videoTitle, setVideoTitle] = useState("");
	const { getFormattedPhrase } = useTranslationContext();
	const { profileResponse, videos } = useSelector((state: RootState) => state.user);
	const [raterWarningText, setRaterWarningText] = useState<string>("");

	useEffect(() => {
		let phrase = getFormattedPhrase("dashboard.tile.rater.instructions.none");
		setRaterWarningText(phrase);
	}, []);

	const getActiveMedia = (thumbnails: boolean) => {
		if (videoPlayerOpen === "360") {
			if (thumbnails) {
				return [videos.self360.thumb];
			} else {
				return [videos.self360.video];
			}
		} else if (videoPlayerOpen === "self") {
			if (thumbnails) {
				return [videos.self.thumb];
			} else {
				return [videos.self.video];
			}
		} else {
			if (thumbnails) {
				return [videos.external.thumb];
			} else {
				return [videos.external.video];
			}
		}
	};

	const getVideoName = (type: string) => {
		switch (type) {
			case "360": return "360 Assessment";
			case "self": return "Self Assessment";
			default: return "External Assessment";
		}
	}

	return (
		<Grid
			container
			className="instrument-status"
			sx={{
				display: "flex",
				alignItems: "center",
				marginTop: 5,
				paddingX: 2,
				backgroundColor:
					instrumentStatus?.raterInvitedCount === 0 &&
						(instrumentStatus?.selfStatus?.statusId === 3 ||
							instrumentStatus?.selfStatus?.statusId === 4)
						? theme.palette.primary.light
						: theme.palette.secondary.dark,
			}}
		>
			{(instrumentStatus?.isSurveyAvailable || instrumentStatus?.documentInfo?.documentId || instrumentStatus?.assetInfo?.assetInfoId) ?
				<>
					<VideoModal
						isOpen={videoPlayerOpen ? true : false}
						onClose={() => { setVideoPlayerOpen(""); setVideoTitle(""); }}
						videoList={getActiveMedia(false)}
						thumbnailList={getActiveMedia(true)}

						name={getVideoName(videoPlayerOpen)}
						assessmentName={videoTitle}
						playedFrom={"Dashboard"}
					/>
					<Grid item xs={window.innerWidth > 1024 ? 8 : 12} className="survey-card">
						<Stack flexDirection={"row"}>
							<Stack flexDirection={"row"}>
								{instrumentStatus?.documentInfo?.documentId ? (
									<Stack direction={"row"}>
										<Typography variant="h5">
											<i className="fa-regular fa-file-pdf" />
											&nbsp;
											{instrumentStatus?.assessmentItemName}
										</Typography>
									</Stack>
								) : (
									<Stack flexDirection={"row"} alignItems={"center"}>
										<Typography
											variant="h5"
											p={1}
											sx={{
												overflowX: "auto",
												wordWrap: "break-word",
												width:
													instrumentStatus &&
														instrumentStatus?.assessmentItemName?.length > 60
														? "80%"
														: "100%",
											}}
										>
											{instrumentStatus?.assessmentItemName}
										</Typography>
										{
											// instrumentStatus?.selfStatus?.statusId === 3 ||
											instrumentStatus?.selfStatus?.statusId === 4 ? (
												<Typography
													className="icon-tick"
													sx={{
														color: theme.palette.success.main,
														fontSize: 22,
														ml: 3,
														alignSelf: "flex-start",
														display: 'none'
													}}
												>
													<i className="fa-duotone fa-check"></i>
												</Typography>
											) : instrumentStatus?.isAssessment ? (
												<IconButton
													sx={{ color: "#307E92" }}
													onClick={() => {
														setVideoPlayerOpen(
															instrumentStatus?.is360 ? "360" :
																instrumentStatus?.instrumentTypeId === 6 ? "external" : "self"
														);
														setVideoTitle(instrumentStatus.assessmentItemName ?? "");
													}}
												>
													<VideoCameraBackIcon />
												</IconButton>
											) : <Fragment /> //can be used for PDFs
										}
									</Stack>
								)}
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={12} sx={{ p: 4, pt: 0 }}>
						<Typography variant="body1" sx={{ fontWeight: 400, direction: "initial" }}>
							{instrumentStatus?.localizedLongDescription}
						</Typography>
					</Grid>
					{instrumentStatus?.is360 &&
						<Grid item xs={12} className="survey-card" pl={4}>
							<DisplayWarnings
								surveyName={instrumentStatus?.assessmentItemName}
								warningText={raterWarningText}
								alertText={instrumentStatus.raterInvitedCount > 0 ? instrumentStatus?.needMoreRaters ? getFormattedPhrase("dashboard.tile.rater.alert") : "" : ""}
								buttonText={instrumentStatus?.needMoreRaters ? getFormattedPhrase("dashboard.tile.rater.button.add") : getFormattedPhrase("dashboard.tile.rater.button.manage")}
								surveyId={instrumentStatus?.instrumentId}
								participantId={instrumentStatus?.participantId}
								complete={!instrumentStatus?.needMoreRaters}
							/>
						</Grid>
					}
					<Grid item xs={12} className="survey-card" pl={4} mt={-4}>
						<Box
							className="survey-warnings"
							sx={{ backgroundColor: theme.palette.secondary.contrastText, borderRadius: instrumentStatus && instrumentStatus?.is360 ? "0 0 15px 15px" : "15px", marginTop: instrumentStatus && instrumentStatus?.is360 ? 0 : 4 }}
						>
							<Grid container display={"flex"} alignItems={"center"}>
								<Grid item xs={12} md={7} display={"flex"} flexDirection={"row"}>
									{(instrumentStatus?.documentInfo?.documentId || !instrumentStatus?.isAssessment)
										? <></>
										: instrumentStatus?.selfStatus?.statusId >= 3
											? <>
												<Typography>{getFormattedPhrase("dashboard.tile.self.instructions.done")}</Typography>
												<CheckCircleIcon
													sx={{
														cursor: "default",
														fontWeight: 500,
														fontSize: "0.938rem",
														borderRadius: "100px",
														alignContent: "center",
														height: 15,
														width: 15,
														mt: 1,
														ml: 2,
														borderWidth: 1,
														color: "#4B7E37",
													}}
													className="btn btn-outline-primary"
												/>
											</>
											: <Typography>{getFormattedPhrase("dashboard.tile.self.instructions.new")}</Typography>
									}
								</Grid>
								<Grid item xs={12} md={5}>
									{instrumentStatus?.documentInfo?.documentId ? (
										<DisplayDocumentStatus
											documentInfo={instrumentStatus?.documentInfo}
											participantId={instrumentStatus?.participantId as number}
											individualId={profileResponse?.individual?.individualId}
											instrumentStatus={instrumentStatus}
										/>
									) : (
										<DisplayInstramentLink
											screenReaderTitle={instrumentStatus?.assessmentItemName}
											screenReaderDescription={instrumentStatus?.localizedLongDescription}
											instrumentStatus={instrumentStatus}
											participantId={instrumentStatus?.participantId as number}
											instrumentId={instrumentStatus?.instrumentId as number}
											statusId={instrumentStatus?.selfStatus?.statusId as number}
											surveyTypeId={surveyTypeId}
											category={category}
											assetAvailable={instrumentStatus?.assetAvailable}
										/>
									)}
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</> : <></>}
		</Grid>
	);
};

type ComponentProps = {
	programStatuses: Array<ProgramStatus>;
	disable?: boolean;
};

export const DisplayProgramInstrumentStatus: React.FC<ComponentProps> = ({
	programStatuses,
	disable,
}) => {
	const theme = useTheme();
	const { dateFormat } = useSelector((state: RootState) => state.user);

	const getSortedInstrumentStatuses = (ins: any) => {
		let sortedInstruments = ins.slice().sort((a: any, b: any) => ((a.assetInfo?.assetInfoId && a.formMasterId) - b.assetInfo?.assetInfoId));
		return sortedInstruments;
	}

	return (
		<Fragment>
			{programStatuses?.length === 0 ? (
				<Loading />
			) : (
				<Fragment>
					{programStatuses?.length > 0 &&
						// .sort((a: ProgramStatus, b: ProgramStatus) => {
						// 	return a.programStartDate > b.programStartDate ? 1 : -1;
						// })
						// .reverse()
						programStatuses.map((ps: ProgramStatus, index: number) => {
							let instrumentStatuses = ps.instrumentStatuses.filter(
								(s) => s.instrumentId !== 2946
							); // Exclude AboutMe, which is displayed elsewhere

							let programComplete =
								ps.instrumentStatuses.filter(
									(a) => a?.selfStatus?.statusId !== 4
								).length === 0;
							return (
								<>
									{instrumentStatuses?.length > 0 &&
										<Card
											key={index}
											sx={{
												px: "5px",
												py: "5px",
												mb: "20px",
											}}
										>
											<CardContent>
												<Stack direction="row">
													<Stack sx={{ flex: 1 }}>
														<Typography variant="title" fontWeight={700}>{ps.name}</Typography>
														<Typography
															variant="caption"
															mt={1}
															sx={{
																alignSelf: "flex-start",
																textTransform: "capitalize",
																color: "#76777B",
																fontWeight: 400,
															}}
														>
															<FormattedPhrase
																id="participant.header.completeBy"
																values={{
																	completeBy:
																		moment(ps.assessmentDueDate).format(dateFormat) + "",
																}}
															/>
														</Typography>
													</Stack>
													{programComplete && (
														<Stack alignSelf={"center"} direction="row">
															<CheckCircleIcon
																sx={{
																	cursor: "default",
																	fontWeight: 500,
																	fontSize: "0.938rem",
																	borderRadius: "100px",
																	alignContent: "center",
																	height: 15,
																	width: 15,
																	mt: 1,
																	ml: 2,
																	borderWidth: 1,
																	color: "#4B7E37",
																}}
																className="btn btn-outline-primary"
															/>
														</Stack>
													)}
												</Stack>
												<Box mt={2}>
													{instrumentStatuses?.length > 0 &&
														getSortedInstrumentStatuses(instrumentStatuses).map(
															(
																instrumentStatus: InstrumentStatus,
																index: number
															) => (
																<DisplayInstrumentStatus
																	key={index}
																	instrumentStatus={instrumentStatus}
																	surveyTypeId={0}
																	category={"assessment"}
																	programStatus={programComplete}
																	disable={disable}
																/>
															)
														)}
												</Box>
											</CardContent>
										</Card>
									}
								</>
							);
						})}
				</Fragment>
			)}
		</Fragment>
	);
};
