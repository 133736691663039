import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	FormControl,
	Grid,
	InputLabel,
	Select,
	Typography,
} from "@mui/material";

import { SurveyAnswer } from "../../../../@types/surveys.d";

import { RootState } from "../../../../store";
import { setActiveAnswers, setLoading } from "../../../../store/User/UserSlice";

import { saveItemResponse } from "../../../../services/apiService";

import { QuestionProps } from "../DisplayQuestion";
import { getSelectedAnswer } from "../../store/SurveyState";
import { FormattedPhrase } from "../../../../context/FormattedPhrase";
import { useTranslationContext } from "../../../../context/TranslationContext";
import { AlertModal } from "../../../shared/alertModal";

export const SurveyAAndBQuestionsComponent: React.FC<QuestionProps> = ({
	question,
	questionGroup,
}) => {
	const dispatch = useDispatch();
	const { getFormattedPhrase } = useTranslationContext();
	const { activeSurvey, activeAnswers, instrumentId, localSurveyLanguage } = useSelector(
		(state: RootState) => state.user
	);
	const [alertOpen, setAlertOpen] = useState(false);

	const handleAandBResponse = async (
		formItemId: number,
		sequenceNum: number,
		response: string
	) => {
		try {
			dispatch(setLoading(true));

			let updated = [...activeAnswers];
			await saveItemResponse(
				activeSurvey.surveyDocument.id,
				instrumentId,
				formItemId,
				sequenceNum,
				response
			).then((res) => {
				let newAnswer: SurveyAnswer = {
					surveyDocumentId: activeSurvey.surveyDocument.id,
					formItemId: res.answers[0].formItemId,
					formItemSequenceNumber: sequenceNum,
					optional: res.answers[0].optional,
					answer: res.answers[0].answer,
					answeredTimeMs: res.answers[0].answeredTimeMs,
				};
				updated.push(newAnswer);
			});

			let otherOption = question.options?.find(
				(o) => o.formItemSequenceNumber !== sequenceNum
			);
			if (otherOption) {
				let otherResp = "";
				switch (response) {
					case "0":
						otherResp = "3";
						break;
					case "1":
						otherResp = "2";
						break;
					case "2":
						otherResp = "1";
						break;
					case "3":
						otherResp = "0";
						break;
				}
				await saveItemResponse(
					activeSurvey.surveyDocument.id,
					instrumentId,
					otherOption.formItemId as unknown as number,
					otherOption.formItemSequenceNumber as number,
					otherResp
				).then((res) => {
					let newAnswer = {
						surveyDocumentId: activeSurvey.surveyDocument.id,
						formItemId: res.answers[0].formItemId,
						formItemSequenceNumber: sequenceNum,
						optional: res.answers[0].optional,
						answer: res.answers[0].answer,
						answeredTimeMs: res.answers[0].answeredTimeMs,
					};
					updated.push(newAnswer);
				});
			}
			dispatch(setActiveAnswers(JSON.stringify(updated)));
		}
		catch (exception) {
			// no new updates
			let updated = [...activeAnswers];
			dispatch(setActiveAnswers(JSON.stringify(updated)));
			setAlertOpen(true);
		} finally {
			dispatch(setLoading(false));
		}
	};

	const [answerA, setAnswerA] = useState<string>("");
	const [answerB, setAnswerB] = useState<string>("");

	useEffect(() => {
		if (question.options && getSelectedAnswer) {
			let a = getSelectedAnswer(
				question.options[0].formItemId ?? 0,
				activeAnswers
			);
			setAnswerA(a);

			let b = getSelectedAnswer(
				question.options[1].formItemId ?? 0,
				activeAnswers
			);
			setAnswerB(b);
		}
	}, [question, activeAnswers]);

	return (
		<Grid container>
			<AlertModal text={getFormattedPhrase("error.savingResponse")} onClose={() => setAlertOpen(false)} isOpen={alertOpen} />
			{question.options?.map((option, index) => {
				if (option?.formItemId) {
					return (
						<Fragment key={index}>
							<Grid item xs={6} sx={{ alignItems: "center" }}>
								<Typography gutterBottom variant="body2" component="div">
									{option.number}.&nbsp;
									{option.text}
								</Typography>
							</Grid>
							<Grid item xs={4} sx={{ marginBottom: 5, marginTop: -2 }}>
								<FormControl
									fullWidth
									sx={{ justifyContent: "center", display: "flex" }}
								>
									<Select
										color={"secondary"}
										native
										id="demo-simple-select"
										labelId="demo-simple-select"
										variant="outlined"
										size="small"
										value={index === 0 ? answerA : answerB}
										onChange={(e: any) => {
											handleAandBResponse(
												option?.formItemId ?? 0,
												option?.formItemSequenceNumber ?? 0,
												e.target.value
											);
										}}
									>
										<Fragment key={index}>
											<option
												selected={index === 0 ? answerA === "" : answerB === ""}
											>
												{getFormattedPhrase("surveys.selectResponse", null, localSurveyLanguage)}
											</option>
											{questionGroup?.answerGroup &&
												questionGroup?.answerGroup?.map((group, gindex) => {
													return (
														<option key={gindex} value={group.value}>
															{group.text}
														</option>
													);
												})}
										</Fragment>
									</Select>
								</FormControl>
							</Grid>
						</Fragment>
					);
				}
			})}
		</Grid>
	);
};
